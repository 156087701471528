import axios from "axios";

export function requestFetch(section) {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/file/${section}`
	});
}

export function requestUpload(section, data) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/admin/file/upload/${section}`,
		headers: {
			"Content-Type": "multipart/form-data"
		},
		withCredentials: true,
		data
	});
}

export function requestMove(section, position, direction) {
	return axios.request({
		method: "patch",
		url: `${process.env.REACT_APP_HOST}/api/admin/file/move/${section}/${position}/${direction}`,
		withCredentials: true
	});
}

export function requestRemove(section, id) {
	return axios.request({
		method: "delete",
		url: `${process.env.REACT_APP_HOST}/api/admin/file/delete/${section}/${id}`,
		withCredentials: true
	});
}

export function requestChange(id, title) {
	return axios.request({
		method: "patch",
		url: `${process.env.REACT_APP_HOST}/api/admin/file/change/${id}`,
		data: {
			title: title
		},
		withCredentials: true
	});
}
