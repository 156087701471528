import { useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import { fetchFile } from "../../redux";

import "./style.sass";

function File({ section, filesState, fetchFile }) {
	const [files, setFiles] = useState(filesState?.[section]);

	useEffect(() => {
		if (files === undefined) fetchFile(section);
	}, []);

	useEffect(() => {
		if (Array.isArray(filesState)) setFiles(filesState?.[section]);
	}, [filesState, section]);

	return (
		<section>
			<h2>Dokumenty</h2>
			<div className="file__list">
				{files && files.length
					? files?.map((file, key) => {
							const imageRegex = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
							const isImage = imageRegex.test(file.source);

							return (
								<a
									key={key}
									className={cx({
										file__icon__image: isImage
									})}
									href={`/data/files/document/${file.source}`}
									download
								>
									{file.title}
								</a>
							);
					  })
					: "zatím žádné dokumenty"}
			</div>
		</section>
	);
}

function mapStateToProps(state) {
	return {
		filesState: state.file
	};
}

const mapDispatchToProps = {
	fetchFile
};

export default connect(mapStateToProps, mapDispatchToProps)(File);
