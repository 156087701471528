import { Link } from "react-router-dom";

import Menu from "../Menu";

import "./style.sass";

export default function Footer() {
	return (
		<footer>
			<section>
				<h2>stránky</h2>
				<Menu withTitle={false} />
			</section>
			<section>
				<h2>kontakt</h2>
				<div>
					<dl>
						<dt>Název</dt>
						<dd>Tělocvičná jednota Sokol Libuš</dd>
						<dt>IČ</dt>
						<dd>613 87 258</dd>
						<dt>Adresa</dt>
						<dd>Libušská 294/129, 142 00 Praha 4 - Libuš</dd>
						<dt>Telefon</dt>
						<dd>
							<a href="tel:261912456">+420 261 912 456</a> (po-pá 8:00 - 12:00 hod.)
						</dd>
						<dt>Email</dt>
						<dd>
							<a href="mailto:sokollibus@volny.cz">sokollibus@volny.cz</a>
						</dd>
						<dt>Dopravní spojení</dt>
						<dd>přímo u zastávky MHD &quot;U Libušské sokolovny&quot;</dd>
					</dl>
				</div>
			</section>
			<section>
				<h2>odkazy</h2>
				<div>
					<a
						href="https://calendar.google.com/calendar/u/0?cid=aW85cWZtamU1cjUzM2pocmFrdTZxb2lrazBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"
						target="_blank"
						rel="noreferrer"
					>
						kalendář turnajů
					</a>
					<a href="https://www.sokollibus.cz/" target="_blank" rel="noreferrer">
						Sokol Libuš
					</a>
					<a href="http://www.lacrosse.cz/" target="_blank" rel="noreferrer">
						Česká lakrosová unie
					</a>
					<Link to="/administrace">administrace</Link>
					<a href="https://www.linkedin.com/in/martin-fried-1213051a8/" target="_blank" rel="noreferrer">
						autor webu
					</a>
				</div>
			</section>
		</footer>
	);
}
