import Content from "../../components/Content";
import Event from "../../components/Event";
import File from "../../components/File";

export default function Home() {
	return (
		<>
			<Content id="aboutus" />
			<Event />
			<Content id="contact" title="Kontakt" />
			<Content id="training" title="Tréninky" />
			<Content id="contribution" title="Podpořte nás" />
			<File section="document" />
		</>
	);
}
