import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import createSagaMiddleware from "@redux-saga/core";

import { watcherSagaContent, watcherSagaUser, watcherSagaFile, watcherSagaEvent } from "../sagas/saga";

import rootReducer from "./reducer";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk, sagaMiddleware]
});

sagaMiddleware.run(watcherSagaContent);
sagaMiddleware.run(watcherSagaUser);
sagaMiddleware.run(watcherSagaFile);
sagaMiddleware.run(watcherSagaEvent);

export default store;
