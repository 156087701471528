import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginUser, statusUser } from "../../redux/user/actions";

import "./style.sass";

function Login({ status, loginUser, statusUser }) {
	const [password, setPassword] = useState("");
	const [showMessage, setShowMessage] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (status === "unchecked") {
			loginUser();
		} else if (status === "wrong") {
			setShowMessage(true);
			statusUser("logout");

			setTimeout(() => {
				setShowMessage(false);
			}, 3000);
		} else if (status === "logged") {
			navigate("/administrace");
		}
	}, [status, navigate, statusUser, loginUser]);

	return (
		<form
			id="login"
			onSubmit={(e) => {
				e.preventDefault();
				loginUser(password);
			}}
		>
			<h2>Přihlášení</h2>
			{showMessage && <span>špatné heslo</span>}
			<input type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
			<button type="submit">přihlásit</button>
		</form>
	);
}

function mapStateToProps(state) {
	return {
		status: state.user.status
	};
}

const mapDispatchToProps = {
	loginUser,
	statusUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
