import {
	CONTENT_FETCH,
	CONTENT_UPDATE,
	CONTENT_SET,
	CONTENT_STATUS
} from "./types";

export const fetchContent = (id) => {
	return {
		type: CONTENT_FETCH,
		id
	};
};

export const updateContent = (id, content) => {
	return {
		type: CONTENT_UPDATE,
		id,
		content
	};
};

export const setContent = (id, content) => {
	return {
		type: CONTENT_SET,
		id,
		content
	};
};

export const statusContent = (status) => {
	return {
		type: CONTENT_STATUS,
		status
	};
};
