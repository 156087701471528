import { call, put } from "redux-saga/effects";

import { setEvent, actionUser } from "../../redux";
import { requestFetch, requestRemove, requestUpload, requestUpdate } from "../requests/event";

export function* handleEventFetch(args) {
	const { section } = args;

	try {
		const response = yield call(requestFetch, section);
		yield put(setEvent(response.data));
	} catch (error) {
		console.log(error);
	}
}

export function* handleEventUpload(args) {
	const { content } = args;

	try {
		const response = yield call(requestUpload, content);
		yield put(setEvent(response.data));
		yield put(actionUser({ type: "event", id: "new", status: "success" }));
	} catch (error) {
		console.log(error);
		yield put(actionUser({ type: "event", id: "new", status: "error" }));
	}
}

export function* handleEventRemove(args) {
	const { id } = args;

	try {
		yield call(requestRemove, id);
	} catch (error) {
		console.log(error);
	}
}

export function* handleEventUpdate(args) {
	const { id, content } = args;

	try {
		yield call(requestUpdate, content);
		yield put(actionUser({ type: "event", id: id, status: "success" }));
	} catch (error) {
		console.log(error);
		yield put(actionUser({ type: "event", id: id, status: "error" }));
	}
}
