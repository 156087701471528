import { takeEvery, takeLeading } from "redux-saga/effects";

import { CONTENT_FETCH, CONTENT_UPDATE } from "../redux/content/types";
import { USER_LOGIN } from "../redux/user/types";
import { FILE_CHANGE, FILE_FETCH, FILE_MOVE, FILE_REMOVE, FILE_UPLOAD } from "../redux/file/types";
import { EVENT_FETCH, EVENT_REMOVE, EVENT_UPDATE, EVENT_UPLOAD } from "../redux/event/types";

import { handleContentFetch, handleContentUpdate } from "./handlers/content";
import { handleUserLogin } from "./handlers/user";
import { handleFileFetch, handleFileMove, handleFileRemove, handleFileUpload, handleFileChange } from "./handlers/file";
import { handleEventFetch, handleEventRemove, handleEventUpdate, handleEventUpload } from "./handlers/event";

export function* watcherSagaContent() {
	yield takeLeading(CONTENT_FETCH, handleContentFetch);
	yield takeEvery(CONTENT_UPDATE, handleContentUpdate);
}

export function* watcherSagaUser() {
	yield takeLeading(USER_LOGIN, handleUserLogin);
}

export function* watcherSagaFile() {
	yield takeLeading(FILE_FETCH, handleFileFetch);
	yield takeEvery(FILE_UPLOAD, handleFileUpload);
	yield takeEvery(FILE_MOVE, handleFileMove);
	yield takeEvery(FILE_REMOVE, handleFileRemove);
	yield takeEvery(FILE_CHANGE, handleFileChange);
}

export function* watcherSagaEvent() {
	yield takeLeading(EVENT_FETCH, handleEventFetch);
	yield takeEvery(EVENT_UPLOAD, handleEventUpload);
	yield takeEvery(EVENT_REMOVE, handleEventRemove);
	yield takeEvery(EVENT_UPDATE, handleEventUpdate);
}
