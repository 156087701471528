import { useLocation, Link } from "react-router-dom";
import cx from "classnames";

import "./style.sass";

export default function Menu({ withTitle = true }) {
	const location = useLocation();

	return (
		<>
			{withTitle && (
				<div id="banner__title">
					<picture>
						{location.pathname !== "/administrace" && (
							<source srcSet="/images/logo_white.png" media="(prefers-color-scheme: dark)" />
						)}
						<img src="/images/logo.png" alt="logo Lakros Libuš" />
					</picture>
					<h1>Lakros Libuš</h1>
				</div>
			)}
			<menu>
				<Link className={cx({ active: location.pathname === "/" })} to="/">
					hlavní stránka
				</Link>
				<Link className={cx({ active: location.pathname === "/turnaje" })} to="/turnaje">
					turnaje
				</Link>
				<Link className={cx({ active: location.pathname === "/kontakt" })} to="/kontakt">
					kontakt
				</Link>
				<Link
					className={cx({
						active: location.pathname === "/treninky"
					})}
					to="/treninky"
				>
					tréninky
				</Link>
				<Link
					className={cx({
						active: location.pathname === "/prispevek"
					})}
					to="/prispevek"
				>
					podpořte nás
				</Link>
				<Link
					className={cx({
						active: location.pathname === "/dokumenty"
					})}
					to="/dokumenty"
				>
					dokumenty
				</Link>
				<a
					href="https://flickr.com/photos/194010186@N04/albums"
					target="_blank"
					rel="noreferrer"
					className="classic"
				>
					fotky
				</a>
				<a
					href="https://www.youtube.com/channel/UCyB_isAAOmfmM87MTmKJ2Hg/videos"
					target="_blank"
					rel="noreferrer"
					className="classic"
				>
					videa
				</a>
				<a href="/images/letak_ukrajina.jpg" className="classic" download>
					<img src="/images/icons/ukraine_flag.svg" />
				</a>
			</menu>
		</>
	);
}
