import { useState, useEffect } from "react";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import "moment/locale/cs";
import _ from "lodash";

import "./style.sass";

export default function List({ month, selected, events }) {
	const [currEvents, setCurrEvents] = useState([]);

	moment.locale("cs");

	useEffect(() => {}, [selected]);

	useEffect(() => {
		selected
			? setCurrEvents(
					events?.filter(
						(event) =>
							moment(event.begin).isBefore(moment(selected).add(1, "days")) &&
							moment(event.till).isAfter(moment(selected).subtract(1, "days"))
					)
			  )
			: setCurrEvents(
					events?.filter(
						(event) =>
							moment(event.begin).month() === month &&
							moment(event.begin).isAfter(moment().subtract(1, "days"))
					)
			  );
	}, [month, selected, events]);

	return (
		<div className="list">
			{_.isEmpty(currEvents) ? (
				<span className="list__empty">
					{selected === undefined ? "tento měsíc nejsou žádné události" : "vybraný datum není žádná akce"}
				</span>
			) : (
				currEvents?.map(({ begin, till, place, title, description }, key) => (
					<dl key={key}>
						<dt className="list__date">
							{moment(begin).format("dddd D. M. Y")}
							{moment(begin).isSame(moment(till)) ? " " : " - " + moment(till).format("D. M. Y")}
							{place && ` | ${place}`}
						</dt>
						<dt>{title}</dt>
						<dd>{HTMLReactParser(description)}</dd>
					</dl>
				))
			)}
		</div>
	);
}
