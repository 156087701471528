import React from "react";

import "./style.sass";

function Sposors() {
	return (
		<div id="sponsors">
			<a href="https://www.praha.eu/" target="_blank" rel="noreferrer" className="icon">
				<img src="/images/sponsors/prague.png" />
			</a>
			<a href="https://www.msmt.cz/" target="_blank" rel="noreferrer" className="icon">
				<img src="/images/sponsors/MSMT.jpg" />
			</a>
			<a href="https://www.praha-libus.cz/" target="_blank" rel="noreferrer" className="icon">
				<img src="/images/sponsors/praha-libus.png" />
			</a>
			<a href="https://agenturasport.cz/" target="_blank" rel="noreferrer" className="icon">
				<img src="/images/sponsors/sportovni-agentura.png" />
			</a>
			<a href="https://sokol.eu/" target="_blank" rel="noreferrer" className="icon">
				<img src="/images/sponsors/sokol.png" />
			</a>
			<a href="https://www.lacrosse.cz/" target="_blank" rel="noreferrer" className="icon">
				<img src="/images/sponsors/czech-lakros-union.jpg" />
			</a>
		</div>
	);
}

export default Sposors;
