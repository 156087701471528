import { combineReducers } from "redux";

import contentReducer from "./content/reducer";
import fileReducer from "./file/reducer";
import userReducer from "./user/reducer";
import eventReducer from "./event/reducer";

const rootReducer = combineReducers({
	content: contentReducer,
	user: userReducer,
	file: fileReducer,
	event: eventReducer
});

export default rootReducer;
