import axios from "axios";

export function requestFetch(id) {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/content/${id}`
	});
}

export function requestUpdate(id, content) {
	return axios.request({
		method: "patch",
		url: `${process.env.REACT_APP_HOST}/api/admin/content/${id}`,
		data: content,
		withCredentials: true
	});
}
