import { call, put } from "redux-saga/effects";

import { requestFetch, requestUpload, requestMove, requestRemove, requestChange } from "../requests/file";
import { updateFile, actionUser } from "../../redux";

export function* handleFileFetch(args) {
	const { section } = args;

	try {
		const response = yield call(requestFetch, section);
		yield put(updateFile(section, response.data));
	} catch (error) {
		console.log(error);
	}
}

export function* handleFileUpload(args) {
	const { section, data } = args;

	if (data === undefined) return;

	// Convert files to FormData
	const formData = new FormData();
	Array.from(data).forEach((file, idx) => formData.append(`FILE#${idx}`, file));

	try {
		const response = yield call(requestUpload, section, formData);
		yield put(updateFile(section, response.data));
		yield put(actionUser({ type: "file", id: section, status: "success" }));
	} catch (error) {
		console.log(error);
		yield put(actionUser({ type: "file", id: section, status: "error" }));
	}
}

export function* handleFileMove(args) {
	const { section, position, direction } = args;

	try {
		yield call(requestMove, section, position, direction);
	} catch (error) {
		console.log(error);
	}
}

export function* handleFileRemove(args) {
	const { section, id } = args;

	try {
		yield call(requestRemove, section, id);
	} catch (error) {
		console.log(error);
	}
}

export function* handleFileChange(args) {
	const { id, title } = args;

	try {
		yield call(requestChange, id, title);
	} catch (error) {
		console.log(error);
	}
}
