import { EVENT_FETCH, EVENT_UPLOAD, EVENT_UPDATE, EVENT_SET, EVENT_REMOVE } from "./types";

export const fetchEvent = () => {
	return {
		type: EVENT_FETCH
	};
};

export const uploadEvent = (content) => {
	return {
		type: EVENT_UPLOAD,
		content
	};
};

export const updateEvent = (id, content) => {
	return {
		type: EVENT_UPDATE,
		id,
		content
	};
};

export const removeEvent = (id) => {
	return {
		type: EVENT_REMOVE,
		id
	};
};

export const setEvent = (content) => {
	return {
		type: EVENT_SET,
		content
	};
};
