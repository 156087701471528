import { CONTENT_UPDATE, CONTENT_SET, CONTENT_STATUS } from "./types";

const initialState = {
	aboutus: undefined,
	tournament: undefined,
	contact: undefined,
	training: undefined,
	status: undefined
};

const contentReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONTENT_SET:
		case CONTENT_UPDATE:
			return { ...state, [action.id]: action.content || "zatím prázdné" };
		case CONTENT_STATUS:
			return { ...state, status: action.status };
		default:
			return state;
	}
};

export default contentReducer;
