import moment from "moment";
import { useEffect, useState } from "react";
import cx from "classnames";
import _ from "lodash";

import { MONTHS } from "../../../..";
import "./style.sass";

export default function Calendar({ currMonth, setCurrMonth, events, selected, setSelected }) {
	const dayTitles = ["po", "út", "st", "čt", "pá", "so", "ne"];
	const [date, setDate] = useState(moment().format());
	const [days, setDays] = useState([]);

	useEffect(() => {
		const firstDate = moment(date).startOf("month").isoWeekday(1);
		const lastDate = moment(date).endOf("month").isoWeekday(7);

		let daysToAdd = [];
		let dateCurrent = firstDate;
		while (dateCurrent.isBefore(lastDate)) {
			daysToAdd.push(dateCurrent.format());
			dateCurrent.add(1, "day");
		}
		setDays(daysToAdd);
		setCurrMonth(moment(date).month());
	}, [date]);

	return (
		<div className="calendar">
			<div className="calendar__menu">
				<button onClick={() => setDate(moment(date).subtract(1, "months"))}>
					<picture>
						<source srcSet="/images/icons/previous_white.svg" media="(prefers-color-scheme: dark)" />
						<img src="/images/icons/previous.svg" alt="ikona pro předchozí měsíc" />
					</picture>
				</button>
				<span>
					{MONTHS[moment(date).format("M") - 1]} {moment(date).format("Y")}
				</span>
				<button onClick={() => setDate(moment(date).add(1, "months"))}>
					<picture>
						<source srcSet="/images/icons/next_white.svg" media="(prefers-color-scheme: dark)" />
						<img src="/images/icons/next.svg" alt="ikona pro následující měsíc" />
					</picture>
				</button>
			</div>
			<div className="calendar__header">
				{dayTitles.map((title, key) => (
					<div key={key}>{title}</div>
				))}
			</div>
			<div className="calendar__body">
				{days.map((day, key) => (
					<div
						key={key}
						className={cx({
							calendar__outMonth: moment(day).month() != currMonth,
							calendar__today: moment().isSame(moment(day), "day"),
							calendar__hasEvent: !_.isEmpty(
								events?.filter(
									(event) =>
										moment(event.begin).isBefore(moment(day).add(1, "days")) &&
										moment(event.till).isAfter(moment(day).subtract(1, "days"))
								)
							),
							calendar__selected: moment(day).isSame(selected)
						})}
						onClick={() => setSelected((prevState) => (prevState === day ? undefined : day))}
					>
						{moment(day).format("D")}
					</div>
				))}
			</div>
		</div>
	);
}
