import Carousel from "react-bootstrap/Carousel";
import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { fetchFile } from "../../redux";

import "./style.sass";
import styles from "./banner.module.scss";

function Banner({ imagesState, fetchFile }) {
	const [images, setImages] = useState(imagesState);

	useEffect(() => {
		if (images === undefined) fetchFile("banner");
	});

	useEffect(() => {
		setImages(imagesState);
	}, [imagesState]);

	return (
		<div className={styles.bootstrap}>
			<Carousel>
				{Array.isArray(images)
					? images.map((image, key) => (
							<Carousel.Item key={key}>
								<img src={`${process.env.REACT_APP_HOST}/data/files/banner/${image.source}`} />
							</Carousel.Item>
					  ))
					: null}
			</Carousel>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		imagesState: state.file.banner
	};
}

const mapDispatchToProps = {
	fetchFile
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
