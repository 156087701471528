import { FILE_CHANGE, FILE_MOVE, FILE_REMOVE, FILE_UPDATE } from "./types";

const initialState = {};

const fileReducer = (state = initialState, action) => {
	const { type, section, direction, position, id, values, title } = action;

	// has to be defined, before in case used.
	let movedSection, changedSection;

	switch (type) {
		case FILE_MOVE:
			movedSection = state?.[section]?.map((file, key) => {
				// move down
				if (direction === "down" && (key === position || key === position + 1)) {
					return {
						...file,
						position: position + +(key === position)
					};
				}

				// move up
				if (direction === "up" && (key === position - 1 || key === position)) {
					return {
						...file,
						position: position - +(key === position)
					};
				}

				return file;
			});
			return {
				...state,
				[section]: movedSection.sort((a, b) => a.position - b.position)
			};
		case FILE_REMOVE:
			return {
				...state,
				[section]: state?.[section]
					.filter((file) => file.id !== id)
					.map((file, key) => {
						return { ...file, position: key };
					})
			};
		case FILE_UPDATE:
			return {
				...state,
				[section]: values
			};
		case FILE_CHANGE:
			changedSection = state?.[section]?.map((file) =>
				file.id === id ? { ...{ ...file }, title: title } : file
			);
			return {
				...state,
				[section]: changedSection
			};
		default:
			return state;
	}
};

export default fileReducer;
