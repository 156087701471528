import "./style.sass";

export default function Loading() {
	return (
		<main id="loading">
			<div>
				<img src="/images/icons/loading.svg" alt="Ikona načítání" />
				<h1>Načítání</h1>
			</div>
		</main>
	);
}
