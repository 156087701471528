import { USER_STATUS, USER_LOGIN, USER_ACTION } from "./types";

const initialState = {
	status: "unchecked",
	password: "",
	action: undefined
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case USER_STATUS:
			return {
				status: action.status,
				password: state.password
			};
		case USER_LOGIN:
			return {
				status: state.status,
				password: action.password
			};
		case USER_ACTION:
			return { ...state, action: action.action };
		default:
			return state;
	}
};

export default userReducer;
