import axios from "axios";

export function requestFetch() {
	return axios.request({
		method: "get",
		url: `${process.env.REACT_APP_HOST}/api/event`
	});
}

export function requestUpload(data) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/admin/event/upload`,
		withCredentials: true,
		data
	});
}

export function requestRemove(id) {
	return axios.request({
		method: "delete",
		url: `${process.env.REACT_APP_HOST}/api/admin/event/delete/${id}`,
		withCredentials: true
	});
}

export function requestUpdate(data) {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/admin/event/update`,
		data,
		withCredentials: true
	});
}
