import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";

import Sposors from "./components/Sposors";
import Event from "./components/Event";
import Banner from "./components/Banner";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Content from "./components/Content";
import File from "./components/File";
import Loading from "./components/Loading";
import Login from "./pages/login";
import Home from "./pages/home";
import store from "./redux/store";

import "./assets/theme.sass";
import "./assets/style.sass";

const Administration = lazy(() => import("./pages/administration"));

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Banner />
				<Menu />
				<Routes>
					<Route index exact element={<Home />} />
					<Route
						path="/turnaje"
						element={
							<>
								<Event />
								<Content id="tournament" title="Termíny ligových zápasů" />
							</>
						}
					/>
					<Route path="/kontakt" element={<Content id="contact" title="Kontakt" />} />
					<Route path="/treninky" element={<Content id="training" title="Tréninky" />} />
					<Route path="/dokumenty" element={<File section="document" />} />
					<Route path="/prispevek" element={<Content id="contribution" title="Podpořte nás" />} />
					<Route
						path="/administrace"
						element={
							<Suspense fallback={<Loading />}>
								<Administration />
							</Suspense>
						}
					/>
					<Route path="/login" element={<Login />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
				<Sposors />
				<Footer />
			</BrowserRouter>
		</Provider>
	);
}

export default App;
