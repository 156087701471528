import { call, put } from "redux-saga/effects";

import { requestFetch, requestUpdate } from "../requests/content";
import { setContent, actionUser } from "../../redux";

export function* handleContentFetch(args) {
	const { id } = args;

	try {
		const response = yield call(requestFetch, id);
		yield put(setContent(id, response.data));
	} catch (error) {
		console.log(error);
	}
}

export function* handleContentUpdate(args) {
	const { id, content } = args;

	try {
		yield call(requestUpdate, id, content);
		yield put(actionUser({ type: "content", id: id, status: "success" }));
	} catch (error) {
		console.log(error);
		yield put(actionUser({ type: "content", id: id, status: "error" }));
	}
}
