import { USER_LOGIN, USER_STATUS, USER_ACTION } from "./types";

export const loginUser = (password) => {
	return {
		type: USER_LOGIN,
		password
	};
};

export const statusUser = (status) => {
	return {
		type: USER_STATUS,
		status
	};
};

export const actionUser = (action) => {
	return {
		type: USER_ACTION,
		action
	};
};
