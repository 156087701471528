import { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { fetchEvent } from "../../redux";

import List from "./components/List";
import Calendar from "./components/Calendar";

import "./style.sass";

function Event({ eventsState, fetchEvent }) {
	const [currMonth, setCurrMonth] = useState(moment().month());
	const [selected, setSelected] = useState(undefined);
	const [events, setEvents] = useState(eventsState);

	useEffect(() => {
		if (events === undefined) fetchEvent();
	}, []);

	useEffect(() => {
		if (Array.isArray(eventsState)) setEvents(eventsState);
	}, [eventsState]);

	return (
		<section className="event" onMouseLeave={() => setSelected(undefined)}>
			<h2>Kalendář akcí a turnajů</h2>
			<Calendar
				currMonth={currMonth}
				setCurrMonth={setCurrMonth}
				events={events}
				selected={selected}
				setSelected={setSelected}
			/>
			<List month={currMonth} events={events} selected={selected} />
		</section>
	);
}

function mapStateToProps(state) {
	return {
		eventsState: state.event.events
	};
}

const mapDispatchToProps = {
	fetchEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
