import axios from "axios";

export function requestLogin(password) {
	const credentials = btoa(`user:${password}`);
	const headers = {
		Authorization: `Basic ${credentials}`
	};

	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/admin`,
		headers,
		withCredentials: true
	});
}

export function requestStatus() {
	return axios.request({
		method: "post",
		url: `${process.env.REACT_APP_HOST}/api/admin`,
		withCredentials: true
	});
}
