import moment from "moment";

import { EVENT_UPDATE, EVENT_SET, EVENT_REMOVE } from "./types";

const initialState = {
	events: undefined
};

const eventReducer = (state = initialState, action) => {
	const { type, content, id } = action;

	let restEvents;
	switch (type) {
		case EVENT_UPDATE:
			restEvents = state.events?.filter((event) => event.id !== id);
			return {
				events: [...restEvents, content].sort(
					(a, b) => moment(a.begin).unix() - moment(b.begin).unix() || a.id - b.id
				)
			};
		case EVENT_SET:
			return { events: content };
		case EVENT_REMOVE:
			return { events: state.events?.filter((event) => event.id !== id) };
		default:
			return state;
	}
};

export default eventReducer;
