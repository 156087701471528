import { FILE_FETCH, FILE_UPLOAD, FILE_MOVE, FILE_REMOVE, FILE_UPDATE, FILE_CHANGE } from "./types";

export const fetchFile = (section) => {
	return {
		type: FILE_FETCH,
		section
	};
};

export const uploadFile = (section, data) => {
	return {
		type: FILE_UPLOAD,
		section,
		data
	};
};
export const moveFile = (section, position, direction) => {
	return {
		type: FILE_MOVE,
		section,
		position,
		direction
	};
};

export const removeFile = (section, id) => {
	return {
		type: FILE_REMOVE,
		section,
		id
	};
};

export const updateFile = (section, values) => {
	return {
		type: FILE_UPDATE,
		section,
		values
	};
};

export const changeFile = (section, id, title) => {
	return {
		type: FILE_CHANGE,
		section,
		id,
		title
	};
};
